/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ref, listAll, getDownloadURL, getMetadata, StorageReference, deleteObject } from 'firebase/storage';
import { Button, Card, Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { storage } from '../../firebase/firebaseConfig';
import { IsLoading } from '../ui/IsLoading';
import { UserContext } from '../../context/UserContext';

interface Params {
    id: number;
    canDelete?: boolean;
    scope: 'adopcion' | 'rehabilitacion';
};

export const MascotaCertificado = ({ id, canDelete = false }: Params) => {

    const { languaje } = useContext(UserContext);

    const [loading, setLoading] = useState(false);
    const [cet, setCet] = useState < CertificadoItem[] > ([]);

    const modalImageRef = useRef < any > ();
    const modalRef = useRef < any > ();

    const [show, setShow] = useState < string | null > (null);

    const handleClose = () => setShow(null);
    const handleShow = (key:string) => setShow(key);

    useEffect(() => {
        getCet();
    }, []);

    const getCet = async () => {

        setLoading(true);
        setCet([]);

        const storageRef = ref(storage, `cedula/${id}/`);
        new Promise((resolve, reject) => {
            listAll(storageRef)
                .then((res) => {
                    res.items.forEach(async (itemRef) => {
                        const file = await buildItem(itemRef);
                        resolve(setCet(oldArray => [...oldArray, file]));
                    });
                    setLoading(false);
                }).catch((error) => {
                    console.log(error);
                    reject();
                    setLoading(false);
                });
        });

    };

    const buildItem = (itemRef: StorageReference) => {
        return new Promise < any > (async (resolve, reject) => {

            let type: any;
            let url: any;

            // Get metadata properties
            await getMetadata(itemRef)
                .then((metadata) => {
                    type = metadata.contentType?.includes('video') ? 'video' : 'photo';
                })
                .catch((error) => {
                    console.log(error);
                    reject();
                });

            await getDownloadURL(itemRef)
                .then((downloadURL) => {
                    url = downloadURL;
                })
                .catch((error) => {
                    console.log(error);
                    reject();
                });

            const newItem: CertificadoItem = {
                url,
                type,
                altTag: itemRef.name,
                title: itemRef.name
            };

            resolve(newItem);
        });
    };

    const openImageModal = (url: string) => {
        modalRef.current.style.display = "block";
        modalImageRef.current.style.backgroundImage = `url(${url}})`;

    }

    const closeModal = () => {
        modalRef.current.style.display = "none";
        modalImageRef.current.style.backgroundImage = 'none';
    };

    const handleDelete = (resource: CertificadoItem) => {

        Swal.fire({
            title: languaje === 'ES' ? `¿Está seguro de eliminar esté archivo` : `Are you sure that you want delete this file?`,
            text: languaje === 'ES' ? '¡No podrás revertir esto!' : "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: languaje === 'ES' ? '¡Sí, bórralo!' : 'Yes, delete it!',
            cancelButtonText: languaje === 'ES' ? 'Cancelar' : 'Cancel'
        }).then(async (result) => {
            if (result.isConfirmed) {

                const storageRef = ref(storage, `mascotas/${id}/${resource.title}`);
                deleteObject(storageRef).then(() => {
                    Swal.fire(
                        languaje === 'ES' ? 'Eliminado' : 'Deleted!',
                        languaje === 'ES' ? 'Tú mascota ha sido eliminada con éxito' : 'Your pet has been deleted.',
                        'success'
                    );
                    window.location.reload();
                    //getCet();
                }).catch((error) => {
                    console.log(error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo sentimos ocurrio un error...',
                        text: 'Contacta con el administrador'
                    });
                });
            }
        });


    };

    return (
        <div className='row'>
            {loading ? <div className="col-12 text-center"><IsLoading /></div> :
                <> {cet.map((resource, i) => {
                    return (
                        <div className="col-12 col-md-12 card__container" key={resource.altTag}>
                            <p style={{ cursor: 'pointer' }} onClick={() => handleShow(resource.altTag)}>
                                EVIDENCIA FOTOGRÁFICA O CERTIFICADO DE ESTERILIZACIÓN
                            </p>

                            <Modal className='hiddent-close' show={show === resource.altTag} onHide={handleClose}>
                                <Modal.Header >
                                    <Modal.Title>Modal heading</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {resource.type === 'photo' ?
                                        <img src={resource.url} alt={resource.title} className='img-fluid img-cet' style={{ width: '100%', height: '100%', objectFit: 'cover' }} onClick={() => openImageModal(resource.url)} /> :
                                        <div className="embed-responsive embed-responsive-4by3 pb-4" style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
                                            <iframe className="embed-responsive-item youtube-player" src={resource.url} title={resource.title} allowFullScreen frameBorder={0} ></iframe>
                                        </div>
                                    }

                                    {
                                        canDelete &&
                                        <div className="col-12 d-flex justify-content-center align-items-center position-absolute" style={{ bottom: 0 }}>
                                            <button type='button' className="btn-sm btn-outline-danger w-50 mt-2" onClick={() => handleDelete(resource)}>Eliminar</button>
                                        </div>
                                    }
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="danger" onClick={handleClose}>
                                        Cerrar
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    )
                })}
                    <div className="modal" ref={modalRef}>
                        <div className="overlay"></div>
                        <span className="close" onClick={(() => closeModal())}><FontAwesomeIcon icon={faTimes as IconProp} /></span>
                        <div className="modal-image" ref={modalImageRef}></div>
                    </div>
                </>
            } </div>
    )
}

interface CertificadoItem {
    url: string;
    type: 'photo' | 'video';
    altTag: string;
    title: string;
}
{/* 
                            <p>
                                
                            </p>
                            <Card className='h-100 w-100' style={{ overflow: 'hidden' }}>
                                <Card.Body className='p-0 position-relative'>
                                    

                                    
                                    
                                </Card.Body>
                            </Card>
                      */}