/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import { AxiosResponse } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faTrash,
  faPencilAlt,
  faLevelUpAlt,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

import { Loader } from "../../../components/ui/Loader";
import { useKW2P } from "../../../hooks/useKW2P";
import { UserContext } from "../../../context/UserContext";
import { AuthContext } from "../../../context/AuthContext";
import { MascostasData, Mascota } from "../../../interfaces/mascotasInterfaces";
import {
  mascotaEdad,
  mascotaRaza,
  mascotaSexo,
  mascotaTamano,
} from "../../../helpers/callejeritosFunciones";
// import kw2pApiPublic from "../../../api/kw2pPublic";
import "../../../style.css";
// import { ConsultarUsuariosResp } from "../../../interfaces/usuariosInterfaces";
import { ModalMascotasEstado } from "../Adotadas/MascotasAdotadas";

export const MisRehabilitaciones = () => {
  const ITEMS_PER_PAGE = 15;
  const signalsRef = useRef<Function>();

  const { languaje } = useContext(UserContext);
  const { handleError, setLoading, isLoading, user } = useContext(AuthContext);
  const { consultarCatalogo, eliminarCatalogo, actualizarCatalogoPublic } = useKW2P();

  const [mascotas, setMascotas] = useState < Mascota[] > ([]);
  // const [mascotasDelete, setMascotasDelete] = useState < any > ([]);
  const [usuarioMascotaDuenio, setUsuarioMascotaDuenio] = useState < any > ([]);
  // const [mascotaDeleteFilter, setMascotaDeleteFilter] = useState < any[] > ([]);
  // const [usuarioMascota, setUsuarioMascota] = useState < any > ([]);
  const [mostrarModal, setMostrarModal] = useState(false);
  const [estadoMascota, setEstadoMascota] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageDelete, setCurrentPageDelete] = useState(0);
  const [mascotaEliminar, setMascotaEliminar] = useState < any > ();
  const [mostrarModal2, setMostrarModal2] = useState < any > (false);

  const [queryEliminadas, setQueryEliminadas] = useState < string > ("");
  const [_mascotasEliminadas, setMascotasEliminadas] = useState < any > ([]);
  const [querySinEliminar, setQuerySinEliminar] = useState < string > ("");
  const [_mascotasSinEliminar, setMascotasSinEliminar] = useState < any > ([]);

  const indexado = useMemo(()=>currentPage+1,[currentPage])
  const indexadoDelete = useMemo(()=>currentPageDelete+1,[currentPageDelete])

  const mascotasEliminadas = useMemo(() => {
    return _mascotasEliminadas.filter((ff: any) => {
      return (
        !queryEliminadas ||
        `${ff?.payload?.nombre ?? ""}`
          .toLowerCase()
          .includes(`${queryEliminadas ?? ""}`.toLowerCase())
      );
    });
  }, [_mascotasEliminadas, queryEliminadas]);
  const mascotasSinEliminar = useMemo(() => {
    return _mascotasSinEliminar.filter((ff: any) => {
      return (
        !querySinEliminar ||
        `${ff?.payload?.nombre ?? ""}`
          .toLowerCase()
          .includes(`${querySinEliminar ?? ""}`.toLowerCase())
      );
    });
  }, [_mascotasSinEliminar, querySinEliminar]);

  const mascotasPaginadasDelete = useMemo(() => {
    const mascotaFiltrosDelete = [];
    const nextPage = currentPageDelete;
    const firstIndex = nextPage + ITEMS_PER_PAGE;
    for (let i = 0; i < mascotasEliminadas.length; i++) {
      if (i >= nextPage && i < firstIndex) {
        mascotaFiltrosDelete.push(mascotasEliminadas[i]);
      }
    }
    return mascotaFiltrosDelete;
  }, [mascotasEliminadas, currentPageDelete]);

  const mascotasPaginadas = useMemo(() => {
    const mascotaFiltros = [];
    const nextPage = currentPage;
    const firstIndex = nextPage + ITEMS_PER_PAGE;
    for (let i = 0; i < mascotasSinEliminar.length; i++) {
      if (i >= nextPage && i < firstIndex) {
        mascotaFiltros.push(mascotasSinEliminar[i]);
      }
    }
    return mascotaFiltros;
  }, [mascotasSinEliminar, currentPage]);

  const onDeleteMascota = (mascota: any) => {
    setMascotaEliminar(mascota);
    console.log("mascotaPrueba", mascota);
    setMostrarModal2(true);
  };

  const cerrar2 = () => {
    setMostrarModal2(false);
  };

  const usuario = localStorage.getItem("CA_usuario");
  const rol = JSON.parse(usuario!);

  const fetchData = async () => {
    const arraySinDelete: any = [];
    const arrayDelete: any = [];
    try {
      setLoading(true);
      const {
        data: { data },
      } = await consultarMascotasRehabilitacion();
      data.forEach((datosApi) => {
        datosApi.payload.eliminado === true
          ? arrayDelete.push(datosApi)
          : arraySinDelete.push(datosApi);
      });
      setMascotas(data);
      setMascotasEliminadas(arrayDelete);
      setMascotasSinEliminar(arraySinDelete);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      handleError(err);
    }
  };

  const nextHandler = () => {
    const nextPage = currentPage + ITEMS_PER_PAGE;
    if (nextPage > mascotasSinEliminar.length) {
      return;
    } else {
      setLoading(true);
      setCurrentPage(nextPage);
      setLoading(false);
    }
  };

  const prevHandle = () => {
    const nextPage = currentPage - ITEMS_PER_PAGE;
    if (nextPage < 0) {
      return;
    } else {
      setLoading(true);
      setCurrentPage(nextPage);
      setLoading(false);
    }
  };

  const nextHandler1 = () => {
    const nextPage = currentPageDelete + ITEMS_PER_PAGE;
    if (nextPage > mascotasEliminadas.length) {
      return;
    } else {
      setLoading(true);
      setCurrentPageDelete(nextPage);
      setLoading(false);
    }
  };

  const prevHandle1 = () => {
    const nextPage = currentPageDelete - ITEMS_PER_PAGE;
    if (nextPage < 0) {
      return;
    } else {
      setLoading(true);
      setCurrentPageDelete(nextPage);
      setLoading(false);
    }
  };

  // const fetchDataDelete = async () => {
  //   try {
  //     setLoading(true);
  //     const {
  //       data: { data },
  //     } = await consultarMascotasRehabilitacionEliminadas();
  //     setMascotasDelete(data);
  //     console.log("mascotasDelete", mascotasDelete);
  //     filtrandoMascotas();
  //     setLoading(false);
  //   } catch (err: any) {
  //     setLoading(false);
  //     console.log(err);
  //     handleError(err);
  //   }
  // };

  const consultarMascotasRehabilitacion = (): Promise<
    AxiosResponse<MascostasData>
  > => {
    const usuario = localStorage.getItem("CA_usuario");
    const rol = JSON.parse(usuario!);

    if (rol.roles.includes("Administrador")) {
      const BODY = {
        schema: "mascotas",
        filter: {
          where: [
            {
              field: "payload->estado",
              operator: "=",
              value: "rehabilitacion",
            },
          ],
        },
      };

      return consultarCatalogo(BODY);
    } else {
      const BODY = {
        schema: "mascotas",
        filter: {
          where: [
            {
              field: "payload->estado",
              operator: "=",
              value: "rehabilitacion",
            },
            {
              field: "payload->usuario_id",
              operator: "=",
              value: user?.id,
            },
          ],
        },
      };

      return consultarCatalogo(BODY);
    }
  };

  const banderaEliminar = (mascota: Mascota) => {
    const tiempoTranscurrido = Date.now();
    const hoy = new Date(tiempoTranscurrido);
    console.log(mascota);
    let eliminado = mascota.payload.eliminado;
    eliminado = true;
    cerrar2();
    const body = {
      schema: "mascotas",
      id: mascota?.id,
      payload: {
        ...mascota.payload,
        eliminado: eliminado,
        fecha_eliminado: hoy.toLocaleDateString(),
      },
    };
    return actualizarDatosMascotas(body);
  };

  const banderaDevolver = (mascota: Mascota) => {
    console.log(mascota);
    const fechaActual = new Date();
    let eliminado = mascota.payload.eliminado;
    // let fecha_creado = mascota.payload.fecha_creado;
    eliminado = false;
    // let suma15dias= 15*24*60*60*1000;
    // let sumandoDias = new Date(new Date(fecha_creado).getTime()+ suma15dias);
    // console.log("fecha_creado", sumandoDias.toLocaleDateString());
    // console.log("fecha_creado",new Date(new Date().getTime()+ suma15dias));
    // sumando 15 dias para la fecha de notificación
    let suma15dias = 15 * 24 * 60 * 60 * 1000;
    let sumandoDias = new Date(new Date(fechaActual).getTime() + suma15dias);
    let day: any = sumandoDias.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    let month: any = sumandoDias.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    let year = sumandoDias.getFullYear();
    console.log("day", `${year}-${month}-${day}`);

    // sumando 35 días para la fecha de expiración
    let suma35dias = 35 * 24 * 60 * 60 * 1000;
    let sumandoDiasExpiracion = new Date(
      new Date(fechaActual).getTime() + suma35dias
    );
    let dayExpiracion: any = sumandoDiasExpiracion.getDate();
    if (dayExpiracion < 10) {
      dayExpiracion = "0" + dayExpiracion;
    }
    let monthExpiracion: any = sumandoDiasExpiracion.getMonth() + 1;
    if (monthExpiracion < 10) {
      monthExpiracion = "0" + monthExpiracion;
    }
    let yearExpiracion = sumandoDiasExpiracion.getFullYear();
    console.log(
      "sumandoDiasExpiracion",
      `${yearExpiracion}-${monthExpiracion}-${dayExpiracion}`
    );
    const body = {
      schema: "mascotas",
      id: mascota?.id,
      payload: {
        ...mascota.payload,
        eliminado: eliminado,
        fecha_notificacion: `${year}-${month}-${day}`,
        fecha_expiracion: `${yearExpiracion}-${monthExpiracion}-${dayExpiracion}`,
      },
    };
    return actualizarDatosMascotas(body);
  };

  const actualizarDatosMascotas = (body: any) => {
    actualizarCatalogoPublic(body);
    setTimeout(fetchData, 1000);
    // fetchData();
  };

  // const actualizar = () => {
  //   fetchData();
  // };

  const DataEliminada = () => {
    if (estadoMascota === false) {
      setEstadoMascota(true);
    } else {
      setEstadoMascota(false);
    }
    fetchData();
  };

  // const filtrandoMascotas = () => {
  //   let ArregloDelete = [];
  //   for (let i = 0; i < mascotasDelete.length; i++) {
  //     // console.log("mascotasDelete",mascotasDelete[i]['deleted_at'])
  //     if (mascotasDelete[i]["deleted_at"] !== null) {
  //       // console.log("mascotasDeleteIF",mascotasDelete[i]['deleted_at'])
  //       ArregloDelete.push(mascotasDelete[i]);
  //       setMascotaDeleteFilter([...ArregloDelete]);
  //     }
  //   }
  //   console.log("mascotasDeleteFilter", mascotaDeleteFilter);
  //   console.log("ArregloDelete", ArregloDelete);
  // };

  // const consultarMascotasRehabilitacionEliminadas = (): Promise<
  //   AxiosResponse<MascostasData>
  // > => {
  //   const usuario = localStorage.getItem("CA_usuario");
  //   const rol = JSON.parse(usuario!);

  //   if (rol.roles.includes("Administrador")) {
  //     const BODY = {
  //       schema: "mascotas",
  //       "with-trashed": true,
  //       filter: {
  //         where: [
  //           {
  //             field: "payload->estado",
  //             operator: "=",
  //             value: "rehabilitacion",
  //           },
  //         ],
  //       },
  //     };
  //     return consultarCatalogo(BODY);
  //   } else {
  //     return consultarCatalogo("");
  //   }
  // };

  useEffect(() => {
    if (!user) {
      return;
    }

    fetchData();
  }, []);

  const handleDelete = (mascota: Mascota) => {
    Swal.fire({
      title:
        languaje === "ES"
          ? `¿Está seguro de eliminar a ${mascota.payload.nombre}`
          : `Are you sure that you want delete ${mascota.payload.nombre}?`,
      text:
        languaje === "ES"
          ? "¡No podrás revertir esto!"
          : "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText:
        languaje === "ES" ? "¡Sí, bórralo!" : "Yes, delete it!",
      cancelButtonText: languaje === "ES" ? "Cancelar" : "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setLoading(true);
          const BODY = {
            schema: "mascotas",
            id: mascota.id,
          };
          await eliminarCatalogo(BODY);
          setLoading(false);
          Swal.fire(
            languaje === "ES" ? "Eliminado" : "Deleted!",
            languaje === "ES"
              ? "Tú mascota ha sido eliminada con éxito"
              : "Your pet has been deleted.",
            "success"
          );
          fetchData();
        } catch (err: any) {
          console.log(err);
          handleError(err);
        }
      }
    });
  };

  // const obtenerUsuario = async (id: number) => {
  //   console.log("id", id);
  //   try {
  //     setLoading(true);
  //     const payload = {
  //       id,
  //       limit: 1,
  //       filter: {
  //         where: [
  //           {
  //             field: "id",
  //             operator: "=",
  //             value: id,
  //           },
  //         ],
  //       },
  //     };

  //     const {
  //       data: { data },
  //     } = await kw2pApiPublic.post < ConsultarUsuariosResp > (
  //       "/usuarios:consultar",
  //       payload
  //     );

  //     setUsuarioMascota(data[0]);
  //     console.log("data[0]", data[0]);
  //     setLoading(false);
  //     console.log("usuarioMascota", usuarioMascota);
  //     setMostrarModal(true);
  //   } catch (err: any) {
  //     console.log(err);
  //     handleError(err);
  //   }
  // };

  // const obtenerId = (event: any) => {
  //   let idUsuario = event.target.value;
  //   console.log(idUsuario);
  //   obtenerUsuario(idUsuario);
  // };

  const obtenerIdDueno = (event: any) => {
    let idUsuario = event.target.value;
    console.log("valor_dueño", idUsuario);
    mascotas.forEach((mascota: Mascota) => {
      if (Number(mascota.id) === Number(idUsuario)) {
        console.log("result_mascota", mascota);
        setUsuarioMascotaDuenio(mascota.payload);
      }
    });
    // console.log("result_mascota",resultado)
    setMostrarModal(true);
  };

  const cerrar = () => {
    setMostrarModal(false);
  };

  return (
    <div className="mascotas__container">
      <div className="mascotas__header">
        <div className="row w-100">
          <div className="col">
            <p>
              {languaje === "ES"
                ? "Mis mascotas en rehabilitación"
                : "My pets in Rehabilitation"}
                {` (${ mascotasSinEliminar.length})`}
            </p>
            {rol?.roles?.includes("Administrador") ? (
              <input
                type="text"
                className="form-control w-50"
                style={{ minWidth: '300px' }}
                placeholder={
                  languaje === "ES" ? "Buscar mascota" : "Search pet"
                }
                value={querySinEliminar}
                onChange={(e) => {
                  setCurrentPage(0);
                  setQuerySinEliminar(e.target.value);
                }}
              />
            ) : null}
          </div>

          <div>
            <Link to="/mascotas/registrar" className="btn btn-callejeritos">
              {languaje === "ES" ? "Agregar +" : "Add +"}
            </Link>
          </div>
        </div>
      </div>
      <div className="mascotas__body">
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table table-sm table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{languaje === "ES" ? "NOMBRE" : "NAME"}</th>
                    {rol?.roles?.includes("Administrador") ? (
                      <th>
                        {languaje === "ES" ? "FECHA CREACIACIÓN" : "CREATED AT"}
                      </th>
                    ) : null}
                    <th>{languaje === "ES" ? "RAZA" : "BREED"}</th>
                    <th>COLOR</th>
                    <th>{languaje === "ES" ? "EDAD" : "AGE"}</th>
                    <th>{languaje === "ES" ? "SEXO" : "GENDER"}</th>
                    <th>{languaje === "ES" ? "TAMAÑO" : "SIZE"}</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {mascotasPaginadas.map((mascota: Mascota,i) => (
                    // mascota.payload.eliminado === "" || mascota.payload.eliminado === false ?
                    <tr key={mascota.id}>
                      <td>{indexado + i}</td>
                      <td>{mascota.payload.nombre}</td>
                      {rol?.roles?.includes("Administrador") ? (
                        <td>{mascota.created_at.slice(0, -17)}</td>
                      ) : null}
                      <td>{mascotaRaza(mascota.payload.raza, languaje)}</td>
                      <td>{mascota.payload.color}</td>
                      <td>{mascotaEdad(mascota.payload.edad, languaje)}</td>
                      <td>{mascotaSexo(mascota.payload.sexo, languaje)}</td>
                      <td>{mascotaTamano(mascota.payload.tamano, languaje)}</td>
                      
                      <td>
                        <div className="d-flex justify-content-end" style={{gap: '.5rem'}}>
                        <button
                          value={mascota.id}
                          className="btn btn-sm btn-outline-primary"
                          onClick={obtenerIdDueno}
                        >
                          Dueño
                        </button>
                        <button
                          value={mascota.id}
                          className="btn btn-sm btn-outline-info"
                          onClick={_ => signalsRef.current?.(mascota)}
                          style={{ placeContent: 'center' }}
                        >
                          {languaje === "ES" ? "Cambiar estatus" : "Change status"}
                        </button>
                          <Link
                            to={`/mascotas/rehabilitacion/${mascota.id}`}
                            className="btn btn-sm btn-detalles "
                          >
                            {languaje === "ES" ? "Detalles" : "Details"}
                          </Link>
                          {mascota.payload.expediente_id && (
                            <Link
                              to={`/expediente-azul/expediente/${mascota.payload.nombre}/${mascota.payload.expediente_id}`}
                              className="btn btn-sm btn-outline-primary  font-weight-bold"
                            >
                              {languaje === "ES" ? "Expediente" : "File"}
                            </Link>
                          )}
                          <Link
                            to={`/mascotas/rehabilitacion/editar/${mascota.id}`}
                            className="btn btn-sm btn-editar "
                          >
                            <FontAwesomeIcon icon={faPencilAlt as IconProp} />
                          </Link>
                          <button
                            // onClick={() => banderaEliminar(mascota)}
                            onClick={() => onDeleteMascota(mascota)}
                            className="btn btn-sm btn-outline-danger "
                          >
                            <FontAwesomeIcon icon={faTrash as IconProp} />
                          </button>
                          <button
                            onClick={() => banderaDevolver(mascota)}
                            className="btn btn-sm btn-outline-primary "
                          >
                            {/* <FontAwesomeIcon icon={faLevelUpAlt as IconProp}/> */}
                            +15
                          </button>
                        </div>
                      </td>
                    </tr>
                    // : null
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6"></div>
          <div className="col-6">
            <div className="row">
              <div className="col-6">
                {/* <button className='form-control' onClick={prevHandle}>{languaje === 'ES' ? 'página anterior' : 'previous page'}</button> */}
                <button className="form-control" onClick={prevHandle}>
                  <FontAwesomeIcon icon={faArrowLeft as IconProp} />
                </button>
              </div>
              <div className="col-6">
                {/* <button className='form-control' onClick={nextHandler}>{languaje === 'ES' ? 'siguiente página' : 'next page'}</button> */}
                <button className="form-control" onClick={nextHandler}>
                  <FontAwesomeIcon icon={faArrowRight as IconProp} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {rol?.roles?.includes("Administrador") ? (
        <>
          <div className="mascotas__header mt-2">



            <div className="row w-100 align-items-center">
              <div className="col">
                <p>
                  {languaje === "ES"
                    ? "Mis mascotas en rehabilitación eliminadas"
                    : "My pets for Rehabilitation deleted"}
                </p>
                {estadoMascota && rol?.roles?.includes("Administrador") ? (
                  <input
                    type="text"
                    className="form-control w-50"
                    style={{ minWidth: '300px' }}
                    placeholder={
                      languaje === "ES"
                        ? "Buscar mascota eliminada"
                        : "Search deleted pet"
                    }
                    value={queryEliminadas}
                    onChange={(e) => {
                      setCurrentPageDelete(0);
                      setQueryEliminadas(e.target.value);
                    }}
                  />
                ) : null}
              </div>

              {/* <div className="p-2">
                <button
                  className="btn btn-outline-success botones_center_vertical"
                  onClick={actualizar}
                >
                  Actualizar
                </button>
              </div> */}
              <div className="p-2">
                <button
                  className="btn btn-outline-danger"
                  onClick={DataEliminada}
                >
                  {languaje === "ES" ? "Mascotas Eliminadas" : "DELETED PETS"}
                </button>
              </div>
            </div>
          </div>
          <div className="mascotas__body">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive">
                  <table className="table table-sm table-hover">
                    <thead>
                      <tr>
                        <th>{languaje === "ES" ? "NOMBRE" : "NAME"}</th>
                        {rol?.roles?.includes("Administrador") ? (
                          <th>
                            {languaje === "ES"
                              ? "FECHA CREACIACIÓN"
                              : "CREATED AT"}
                          </th>
                        ) : null}
                        <th>{languaje === "ES" ? "RAZA" : "BREED"}</th>
                        <th>COLOR</th>
                        <th>{languaje === "ES" ? "EDAD" : "AGE"}</th>
                        <th>{languaje === "ES" ? "SEXO" : "GENDER"}</th>
                        <th>{languaje === "ES" ? "TAMAÑO" : "SIZE"}</th>
                        <th>
                          {languaje === "ES"
                            ? "FECHA ELIMINACIÓN"
                            : "DELETED AT"}
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {mascotasPaginadasDelete.map((mascota: Mascota,i) =>
                        estadoMascota ? (
                          <tr key={mascota.id}>
                            <td>{indexadoDelete+i}</td>
                            <td>{mascota.payload.nombre}</td>
                            {rol?.roles?.includes("Administrador") ? (
                              <td>{mascota.created_at.slice(0, -17)}</td>
                            ) : null}
                            <td>
                              {mascotaRaza(mascota.payload.raza, languaje)}
                            </td>
                            <td>{mascota.payload.color}</td>
                            <td>
                              {mascotaEdad(mascota.payload.edad, languaje)}
                            </td>
                            <td>
                              {mascotaSexo(mascota.payload.sexo, languaje)}
                            </td>
                            <td>
                              {mascotaTamano(mascota.payload.tamano, languaje)}
                            </td>
                            <td>{mascota.payload.fecha_eliminado}</td>
                            <td>
                              <button
                                value={mascota.id}
                                className="btn btn-sm btn-outline-primary"
                                onClick={obtenerIdDueno}
                              >
                                Dueño
                              </button>
                            </td>
                            <td>
                              <button
                                value={mascota.id}
                                className="btn btn-sm btn-outline-info"
                                onClick={_ => signalsRef.current?.(mascota)}
                                style={{ placeContent: 'center' }}
                              >
                                {languaje === "ES" ? "Cambiar estatus" : "Change status"}
                              </button>
                            </td>
                            <td>
                              <div className="d-flex justify-content-end">
                                {/* <button onClick={() => handleDelete(mascota)} className="btn btn-sm btn-outline-danger "> */}
                                <button
                                  onClick={() => banderaDevolver(mascota)}
                                  className="btn btn-sm btn-outline-primary "
                                >
                                  <FontAwesomeIcon
                                    icon={faLevelUpAlt as IconProp}
                                  />
                                </button>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-end">
                                {/* <button onClick={() => handleDelete(mascota)} className="btn btn-sm btn-outline-danger "> */}
                                <button
                                  onClick={() => handleDelete(mascota)}
                                  className="btn btn-sm btn-outline-danger "
                                >
                                  <FontAwesomeIcon icon={faTrash as IconProp} />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ) : null
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {estadoMascota ? (
              <div className="row">
                <div className="col-6"></div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-6">
                      {/* <button className='form-control' onClick={prevHandle1}>{languaje === 'ES' ? 'página anterior' : 'previous page'}</button> */}
                      <button className="form-control" onClick={prevHandle1}>
                        <FontAwesomeIcon icon={faArrowLeft as IconProp} />
                      </button>
                    </div>
                    <div className="col-6">
                      {/* <button className='form-control' onClick={nextHandler1}>{languaje === 'ES' ? 'siguiente página' : 'next page'}</button> */}
                      <button className="form-control" onClick={nextHandler1}>
                        <FontAwesomeIcon icon={faArrowRight as IconProp} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      ) : null}

      {mostrarModal ? (
        <div className="mascotas__bodyy contenedor_popup">
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <table className="table table-sm table-hover">
                  <thead>
                    <tr>
                      <th>{languaje === "ES" ? "NOMBRE" : "NAME"}</th>
                      <th>{languaje === "ES" ? "CORREO" : "EMAIL"}</th>
                      <th>{languaje === "ES" ? "TELÉFONO" : "PHONE"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{usuarioMascotaDuenio?.dueno_mascota}</td>
                      <td>{usuarioMascotaDuenio?.dueno_mascota_correo}</td>
                      <td>{usuarioMascotaDuenio?.dueno_mascota_telefono}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-outline-danger"
                          onClick={cerrar}
                        >
                          cerrar
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {isLoading && <Loader />}

      {mostrarModal2 ? (
        <div className="mascotas__bodyy contenedor_popup2" id="eliminarMascota">
          <div className="table-responsive overflow-hidden">
            <table className="table table-sm table-hover">
              <div className="row">
                <div className="col-11">
                  <thead>
                    <p className="eliminar_body">
                      ¿Estas seguro de que quieres eliminar a la mascota?
                    </p>
                  </thead>
                </div>
              </div>
              <div className="row">
                <div className="col"></div>
                <div className="col">
                  <td>
                    <button
                      className="btn btn-sm btn-outline-danger"
                      onClick={cerrar2}
                    >
                      {languaje === "ES" ? "Cerrar" : "Close"}
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-sm btn-outline-danger"
                      onClick={() => banderaEliminar(mascotaEliminar)}
                    >
                      {languaje === "ES" ? "Eliminar" : "Delete"}
                    </button>
                  </td>
                </div>
              </div>
            </table>
          </div>
        </div>
      ) : null}

      {/* @ts-ignore */}
      <ModalMascotasEstado signals={(cb: Function) => signalsRef.current = cb} onFinish={() => {
        fetchData()
      }} />
    </div>
  );
};

