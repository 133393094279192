import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { AuthContext } from '../../context/AuthContext';
import { UserContext } from '../../context/UserContext';
import { callejeritosImages } from '../../helpers/callejeritosImages';
import { LoginForm } from '../Auth/LoginForm';

export const FloatingMenu = () => {

    const { languaje } = useContext(UserContext);
    const { user, status, logOut } = useContext(AuthContext);

    const [showMenu, setShowMenu] = useState(false);



    const handleShow = () => setShowMenu(!showMenu);
    const usuario = localStorage.getItem('CA_usuario');
    const rol = React.useMemo(() => {
        return JSON.parse(usuario!)
    },[usuario])

    const handleCerrarSesion = async () => {
        setShowMenu(false);
        await logOut();
        const keysToRemove = ['CA_apikey', 'CA_JWT', 'CA_usuario'];
        keysToRemove.forEach(k => localStorage.removeItem(k));
    };

    const list = React.useMemo(() => {

        return [
            {
                type: 'link',
                to: '/mis-donativos',
                onClick: () => setShowMenu(false),
                children: (<>
                    <span className="fab-label">{languaje === 'ES' ? 'Mis donativos' : 'My donations'}</span>
                    <img src={callejeritosImages('./Menu/donaciones.png').default} alt="Donaciones" width={30} height={30} />
                </>),
                className: '',
            },
            {
                type: 'link',
                to: '/mascotas/mis-rehabilitaciones',
                onClick: () => setShowMenu(false),
                children: (<>
                    <span className="fab-label">{languaje === 'ES' ? 'Alta y administración' : 'registration and administration'} </span>
                    <img src={callejeritosImages('./Menu/rehabilitaciones.png').default} alt="Rehabilitaciones" width={30} height={30} />
                    <span className="fab-label">{languaje === 'ES' ? 'rehabilitaciones' : 'for rehabilitation'} </span>
                </>),
                className: 'tamaño_menu',
            },
            {
                type: 'link',
                to: '/mascotas/mis-adopciones',
                onClick: () => setShowMenu(false),
                children: (<>
                    <span className="fab-label">{languaje === 'ES' ? 'Alta y administración' : 'Registration and administration'}</span>
                    <img src={callejeritosImages('./Menu/adopciones.png').default} alt="Adopciones" width={30} height={30} />
                    <span className="fab-label">{languaje === 'ES' ? 'adopciones' : 'adoptions'}</span>
                </>),
                className: 'tamaño_menu',
            },
            ...rol?.roles?.includes("Administrador")?[
            {
                type: 'link',
                to: '/mascotas/adoptadas',
                onClick: () => setShowMenu(false),
                children: (<>
                    <span className="fab-label">{languaje === 'ES' ? 'Mascotas Adoptadas' : 'Adopted Pets'}</span>
                    <img src={callejeritosImages('./Menu/adopciones.png').default} alt="Mascotas Adoptadas" width={30} height={30} />
                </>),
                className: '',
            }
            ]:[],
            ...rol?.roles?.includes("Administrador")?[
            {
                type: 'link',
                to: '/eventos/adopcion/registro',
                onClick: () => setShowMenu(false),
                children: (<>
                    <span className="fab-label">{languaje === 'ES' ? 'Eventos de adopción' : 'Bulletin'}</span>
                    <img src={callejeritosImages('./Menu/calendar.svg').default} alt="Adopciones" width={25} height={25} />
                </>),
                className: '',
            }
            ]:[],
            ...rol?.roles?.includes("Administrador")?[
            {
                type: 'link',
                to: '/contacto/boletin',
                onClick: () => setShowMenu(false),
                children: (<>
                    <span className="fab-label">{languaje === 'ES' ? 'Boletin' : 'Bulletin'}</span>
                    <img src={callejeritosImages('./Menu/adopciones.png').default} alt="Adopciones" width={30} height={30} />
                </>),
                className: '',
            }
            ]:[],
            {
                type: 'link',
                to: '/mi-perfil',
                onClick: () => setShowMenu(false),
                children: (<>
                    <span className="fab-label">{languaje === 'ES' ? 'Perfil' : 'Profile'}</span>
                    <img src={callejeritosImages('./Menu/mi_perfil.png').default} alt="Mi perfil" width={30} height={30} />
                </>),
                className: '',
            },
            {
                type: 'link',
                to: '/cambiar-contrasena',
                onClick: () => setShowMenu(false),
                children: (<>
                    <span className="fab-label">{languaje === 'ES' ? 'Cambiar contraseña' : 'Change password'}</span>
                    <img src={callejeritosImages('./Menu/cambiar_contrasena.png').default} alt="Cambiar contraseña" width={30} height={30} />
                </>),
                className: '',
            },
            {
                type: 'none',
                onClick: handleCerrarSesion,
                children: (<>
                    <span className="fab-label">{languaje === 'ES' ? 'Cerrar sesión' : 'Logout'}</span>
                    <img src={callejeritosImages('./Menu/cerrar_sesion.png').default} alt="Cerrar sesion" width={30} height={30} />
                </>),
                className: '',
            }

        ]
    }, [languaje,rol])


    return (
        <div className="fab-container">
            <div className="fab fab-icon-holder" onClick={handleShow} >
                <img src={callejeritosImages('./Menu/icono.png').default} alt="Menú" width={80} height={80} className="menu__icon" />
            </div>
            <ul className="fab-options" style={{ opacity: showMenu ? '1' : '0', display: showMenu ? 'unset': 'none' }} onMouseLeave={()=>{setShowMenu(false)}}>
                {
                    status === 'not-authenticated'
                        ? (
                            <>
                                <div className="login-dw">
                                    <p>{languaje === 'ES' ? 'Inicia sesión' : 'Login'}</p>
                                    <LoginForm />
                                </div>
                            </>
                        ) : (
                            <>

                                <div className="usuario__info" >
                                    <p className="m-0 font-weight-bold">{user?.nombre}</p>
                                    <p className="m-0 font-weight-bold">{user?.correo}</p>
                                </div>
                                {/* <div className="dropdown-divider"></div> */}
                                {list.map((item, i) => {
                                    //@ts-ignore
                                    const Wrapper = item.type === 'link' ? Link : ({ children, to }) => children;
                                    return (
                                        // @ts-ignore
                                        <Wrapper to={item.to} key={i} onClick={item.onClick}>
                                            <li className={`menu_itm${i % 2 ? '' : '_w'} ${item.className || ''}`} onClick={item.type!=='link'?item.onClick:()=>{}}>
                                                {item.children}
                                            </li>
                                        </Wrapper>
                                    )

                                })}
                            </>
                        )
                }
            </ul>
        </div>
    )
}
