/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { useKW2P } from '../../../hooks/useKW2P';
import { AuthContext, UserContext } from '../../../context';
import { MascotaCard } from '../../../components/Mascotas/MascotaCard';
import { Loader } from '../../../components/ui/Loader';
import { MascostasData, Mascota } from '../../../interfaces/mascotasInterfaces';
import { ExpedienteAzulFooter } from '../../../components/ui/ExpedienteAzulFooter';
import { BuscadorComponent } from '../../../components/Mascotas/BuscadorComponent';

export const MascotasRehabilitacion = () => {

    const { languaje } = useContext(UserContext);
    const { handleError, setLoading, isLoading } = useContext(AuthContext);
    const { consultarCatalogoPublic } = useKW2P();

    const [mascotas, setMascotas] = useState<Mascota[]>([]);

    useEffect(() => {
        fetchData([{
            field: 'payload->estado',
            operator: '=',
            value: 'rehabilitacion'
        }]);

        console.log("mascotas", mascotas)
    }, []);

    const fetchData = async (where: any[]) => {
        try {
            setLoading(true);
            const { data: { data } } = await consultarMascotasRehabilitacion(where);
            setMascotas(data);
            setLoading(false);
        } catch (err: any) {
            setLoading(false);
            handleError(err);
        }
    };

    const consultarMascotasRehabilitacion = (where: any[]): Promise<AxiosResponse<MascostasData>> => {
        const BODY = {
            schema: 'mascotas',
            filter: {
                where
            }
        };

        return consultarCatalogoPublic(BODY);
    }

    return (
        <div className='mascotas__container'>
            <div className="mascotas__header">
                    <p>{ languaje === 'ES' ? 'Mascotas en Rehabilitación' : 'Pets in Rehabilitation' }</p>
            </div>
            <div className="mascotas__body">
                <BuscadorComponent  scope='rehabilitacion' fetchData={ fetchData }/>
                <div className="row">
                    {
                        mascotas.map((mascota: Mascota) => (
                            <>
                            { mascota.payload.eliminado === "" || mascota.payload.eliminado === false ? 
                                <div className="col-12 col-md-6 col-xl-3 card__container" key={ mascota.id }>
                                        <MascotaCard mascota={ mascota } scope='rehabilitacion' />
                                </div>
                                : null
                            }
                            </>
                            )
                            )
                        }
                </div>
            </div>
            <div className="mascotas__footer">
                <ExpedienteAzulFooter/>
            </div>
            { isLoading && <Loader/>}
        </div>
    )
}
