import { useContext, useRef, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import { estados } from '../../helpers/callejeritosFunciones';

import states from '../../assets/contanst/states.json';

interface Props {
    scope: 'adopcion' | 'rehabilitacion';
    fetchData: ({where, whereJoin}:{where?: any[], whereJoin?: any[]}) => void;
};

export const BuscadorComponentEvento = ({ scope, fetchData }: Props) => {

    const { languaje } = useContext(UserContext);

    const fehcaElement_de = useRef < HTMLInputElement > (null);
    const fehcaElement_a = useRef < HTMLInputElement > (null);
    const descripcionElement = useRef < HTMLInputElement > (null);
    const direccionElemento = useRef < HTMLInputElement > (null);
    const coloniaElemento = useRef < HTMLInputElement > (null);
    const zipCodeElemento = useRef < HTMLInputElement > (null);
    const ciudadElemento = useRef < HTMLInputElement > (null);
    const estadoElemento = useRef < HTMLSelectElement > (null);

    const tamanoElement = useRef < HTMLSelectElement > (null);
    const sexoElement = useRef < HTMLSelectElement > (null);
    const razaElement = useRef < HTMLSelectElement > (null);
    const especieElement = useRef < HTMLSelectElement > (null);
    const conviveOtrasElement = useRef < HTMLSelectElement > (null);
    const conviveElement = useRef < HTMLSelectElement > (null);
    const conviveNinosElement = useRef < HTMLSelectElement > (null);
    const entrenadoElement = useRef < HTMLSelectElement > (null);
    const educadoElement = useRef < HTMLSelectElement > (null);
    const edadValorElement = useRef < HTMLInputElement > (null);
    const edadUnidadElement = useRef < HTMLSelectElement > (null);
    
    
    const desparasitacionElement = useRef < HTMLSelectElement > (null);
    const esterilizadaElement = useRef < HTMLSelectElement > (null);


    const handleFetch = () => {

        let where: any[] = [
            
        ];
        let whereJoin: any[] = [];

        // campos del evento

        if (descripcionElement.current?.value) {
            where.push({
                field: 'payload->descripcion',
                operator: 'like',
                value: `%${descripcionElement.current.value}%`,
                collate: 'utf8mb4_general_ci'
            });
        }
        if (direccionElemento.current?.value) {
            where.push({
                field: 'payload->lugar->direccion',
                operator: 'like',
                value: `%${direccionElemento.current.value}%`,
                collate: 'utf8mb4_general_ci'
            });
        }
        if (coloniaElemento.current?.value) {
            where.push({
                field: 'payload->lugar->colonia',
                operator: 'like',
                value: `%${coloniaElemento.current.value}%`,
                collate: 'utf8mb4_general_ci'
            });
        }
        if (zipCodeElemento.current?.value) {
            where.push({
                field: 'payload->lugar->cp',
                operator: 'like',
                value: `%${zipCodeElemento.current.value}%`,
                collate: 'utf8mb4_general_ci'
            });
        }
        if (ciudadElemento.current?.value) {
            where.push({
                field: 'payload->lugar->ciudad',
                operator: 'like',
                value: `%${ciudadElemento.current.value}%`,
                collate: 'utf8mb4_general_ci'
            });
        }
        if (fehcaElement_de.current?.value) {
            where.push({
                field: 'payload->fecha',
                operator: '>=',
                value: fehcaElement_de.current.value,
            });
        }
        if (fehcaElement_a.current?.value) {
            where.push({
                field: 'payload->fecha',
                operator: '<=',
                value: fehcaElement_a.current.value,
            });
        }

        if (estadoElemento.current?.value) {
            where.push({
                field: 'payload->lugar->estado',
                operator: '=',
                value: estadoElemento.current.value
            });
        }


        // campos de la mascota

        if (tamanoElement.current?.value) {
            whereJoin.push({
                field: 'payload->tamano',
                operator: "regexp",
                value: `[[:<:]]${tamanoElement.current.value}[[:>:]]`
            });
        }

        if (sexoElement.current?.value) {
            whereJoin.push({
                field: 'payload->sexo',
                operator: "regexp",
                value: `[[:<:]]${sexoElement.current.value}[[:>:]]`
            });
        }

        if (razaElement.current?.value) {
            whereJoin.push({
                field: 'payload->raza',
                operator: '=',
                value: razaElement.current.value
            });
        }

        if (especieElement.current?.value) {
            whereJoin.push({
                field: 'payload->especie',
                operator: '=',
                value: especieElement.current.value
            });
        }

        if (conviveOtrasElement.current?.value) {
            whereJoin.push({
                field: 'payload->convine_otras',
                operator: '=',
                value: conviveOtrasElement.current.value
            });
        };

        if (conviveElement.current?.value) {
            whereJoin.push({
                field: 'payload->convine',
                operator: '=',
                value: conviveElement.current.value
            });
        };

        if (conviveNinosElement.current?.value) {
            whereJoin.push({
                field: 'payload->convine_ninos',
                operator: '=',
                value: conviveNinosElement.current.value
            });
        };

        if (entrenadoElement.current?.value) {
            whereJoin.push({
                field: 'payload->entrenado',
                operator: '=',
                value: entrenadoElement.current.value
            });
        };

        if (desparasitacionElement.current?.value) {
            whereJoin.push({
                field: 'payload->desparasitacion',
                operator: '=',
                value: desparasitacionElement.current.value
            });
        };

        if (esterilizadaElement.current?.value) {
            whereJoin.push({
                field: 'payload->esterilizada',
                operator: '=',
                value: esterilizadaElement.current.value
            });
        };

        if (educadoElement.current?.value) {
            whereJoin.push({
                field: 'payload->educado',
                operator: '=',
                value: educadoElement.current.value
            });
        };

        if (edadValorElement.current?.value && edadUnidadElement.current?.value) {

            whereJoin.push({
                field: 'payload->edad->valor',
                operator: '=',
                value: edadValorElement.current.value
            });

            whereJoin.push({
                field: 'payload->edad->unidad',
                operator: '=',
                value: edadUnidadElement.current.value
            });
        }


        fetchData({where, whereJoin});
    };

    const [edadValor, setEdadValor] = useState('');
    const handleEdadValorChange = () => {
        const test_value = edadValorElement.current?.value.replace(/[^0-9]+/g, "");
        if (test_value)
            setEdadValor(test_value);
        else
            setEdadValor('');
    };
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handleFetch();
        }
    }

    return (
        <div className='row mb-4 align-items-end'>
            <div className="col-12">
                <p className="filter_title">{languaje === 'ES' ? 'Utiliza nuestros campos y filtros de búsqueda' : 'Search and filter'}</p>
            </div>

            <div className="col-12">
                <p className="filter_title">
                    {languaje === 'ES' ? 'Campos del evento' : 'Event fields'}
                </p>
            </div>

            <div className="col-12 col-md-9 mt">
                <label htmlFor="ciudad">{languaje === 'ES' ? 'DESCRIPCIÓN' : 'DESCRIPTION:'}</label>
                <input type="text" id="ciudad" className="form-control" ref={descripcionElement} onKeyDown={handleKeyDown} />
            </div>

            <div className="col-12 col-md-3 mt-2">
                <div className="row">
                    <div className="col-12 col-md-6 pr-2">
                        <label htmlFor="tamano">{languaje === 'ES' ? 'DE' : 'FROM'}</label>
                        <input id="fecha" name="fecha" className="form-control" autoComplete="off" type="date" ref={fehcaElement_de} onChange={handleFetch} />
                    </div>
                    <div className="col-12 col-md-6">
                        <label htmlFor="tamano">{languaje === 'ES' ? 'A' : 'TO'}</label>
                        <input id="fecha" name="fecha" className="form-control" autoComplete="off" type="date" ref={fehcaElement_a} onChange={handleFetch} />
                    </div>
                </div>
            </div>


            <div className="col-12 col-md-4 mt-2">
                <label htmlFor="lugar_direccion">DIRECCIÓN:</label>
                <input id="lugar_direccion" className="form-control" autoComplete="off" ref={direccionElemento} onKeyDown={handleKeyDown} />

            </div>
            <div className="col-12 col-md-3 mt-2">
                <label htmlFor="lugar_colonia">COLONIA:</label>
                <input id="lugar_colonia" className="form-control" autoComplete="off" ref={coloniaElemento} onKeyDown={handleKeyDown} />

            </div>
            <div className="col mt-2" style={{ maxWidth: 106 }}>
                <label htmlFor="lugar_cp">CP:</label>
                <input id="lugar_cp" className="form-control" autoComplete="off" ref={zipCodeElemento} onKeyDown={handleKeyDown} maxLength={5} />

            </div>
            <div className="col-12 col-md-2 mt-2">
                <label htmlFor="lugar_ciudad">CIUDAD:</label>
                <input id="lugar_ciudad" className="form-control" autoComplete="off" ref={ciudadElemento} onKeyDown={handleKeyDown} />

            </div>
            <div className="col-12 col-md-4 mt-2" style={{ maxWidth: 240 }}>
                <label htmlFor="lugar_estado">{languaje === 'ES' ? 'ESTADO' : 'STATE'}</label>
                <select id="lugar_estado" className="form-control" ref={estadoElemento} onChange={handleFetch} >
                    <option value=""></option>
                    {states.map((tab: any) => (
                        <option key={tab.id} value={tab.value}>{tab.label}</option>
                    ))}
                </select>
                {/* @ts-ignore */}

            </div>

            <div className="col-12 mt-4">
                <p className="filter_title">
                    {languaje === 'ES' ? 'Campos de la mascota' : 'Pet fields'}
                </p>
            </div>

            <div className="col-12 col-md-6 col-xl-3 mt-2">
                <label htmlFor="tamano">{languaje === 'ES' ? 'TAMAÑO' : 'SIZE'}</label>
                <select name="tamano" id="tamano" className='form-control' ref={tamanoElement} onChange={handleFetch}>
                    <option value=""></option>
                    <option value='mini'>MINI</option>
                    <option value='pequeño'>{languaje === 'ES' ? 'PEQUEÑO' : 'SMALL'}</option>
                    <option value='mediano'>{languaje === 'ES' ? 'MEDIANO' : 'MEDIUM'}</option>
                    <option value='grande'>{languaje === 'ES' ? 'GRANDE' : 'BIG'}</option>
                    <option value='muy grande'>{languaje === 'ES' ? 'MUY GRANDE' : 'GIANT'}</option>
                </select>
            </div>
            <div className="col-12 col-md-6 col-xl-3 mt-2">
                <label htmlFor="sexo">{languaje === 'ES' ? 'SEXO' : 'GENDER'}</label>
                <select name="sexo" id="sexo" className='form-control' ref={sexoElement} onChange={handleFetch}>
                    <option value=""></option>
                    <option value='macho'>{languaje === 'ES' ? 'MACHO' : 'MALE'}</option>
                    <option value='hembra'>{languaje === 'ES' ? 'HEMBRA' : 'FEMALE'}</option>
                </select>
            </div>
            <div className="col-12 col-md-6 col-xl-3 mt-2">
                <label htmlFor="raza">{languaje === 'ES' ? 'RAZA' : 'BREED'}</label>
                <select name="raza" id="raza" className='form-control' ref={razaElement} onChange={handleFetch}>
                    <option value=""></option>
                    <option value='mestizo'>{languaje === 'ES' ? 'MESTIZO' : 'MONGREL'}</option>
                    <option value='mezcla de raza con mestizo'>{languaje === 'ES' ? 'MEZCLA DE RAZA CON MESTIZO' : 'CROSS-BREED'}</option>
                    <option value='de raza'>{languaje === 'ES' ? 'DE RAZA' : 'BREED'}</option>
                    <option value='no se'>{languaje === 'ES' ? 'NO SE' : 'IDK'}</option>
                </select>
            </div>
            <div className="col-12 col-md-6 col-xl-3 mt-2">
                <label htmlFor="especie">{languaje === 'ES' ? 'ESPECIE' : 'SPECIES'}</label>
                <select name="especie" id="especie" className='form-control' ref={especieElement} onChange={handleFetch}>
                    <option value=""></option>
                    <option value='gato'>{languaje === 'ES' ? 'GATO' : 'CAT'}</option>
                    <option value='perro'>{languaje === 'ES' ? 'PERRO' : 'DOG'}</option>
                </select>
            </div>
            <div className="col-12 col-md-6 col-xl-3 mt-2">
                <label htmlFor="convive_otras">{languaje === 'ES' ? '¿CONVIVE CON OTRAS ESPECIES?' : 'LIVES WITH OTHER SPECIES?'}</label>
                <select name="convive_otras" id="convive_otras" className='form-control' ref={conviveOtrasElement} onChange={handleFetch}>
                    <option value=""></option>

                    <option value='SI'>{languaje === 'ES' ? 'SI' : 'YES'}</option>
                    <option value='NO'>NO</option>
                    <option value='NO SE'>{languaje === 'ES' ? 'NO SE' : 'IDK'}</option>
                </select>
            </div>
            <div className="col-12 col-md-6 col-xl-3 mt-2">
                <label htmlFor="convive_ninos">{languaje === 'ES' ? '¿CONVIVE CON NIÑOS?' : 'KIDS FRIENDLY?'}</label>
                <select name="convive_ninos" id="convive_ninos" className='form-control' ref={conviveNinosElement} onChange={handleFetch}>
                    <option value=""></option>

                    <option value='SI'>{languaje === 'ES' ? 'SI' : 'YES'}</option>
                    <option value='NO'>NO</option>
                    <option value='NO SE'>{languaje === 'ES' ? 'NO SE' : 'IDK'}</option>
                </select>
            </div>

            <div className="col-12 col-md-6 col-xl-3 mt-2">
                <label htmlFor="entrenado">{languaje === 'ES' ? '¿ESTÁ ENTRENADO?' : 'TRAINED?'}</label>
                <select name="entrenado" id="entrenado" className='form-control' ref={entrenadoElement} onChange={handleFetch}>
                    <option value=""></option>
                    <option value='true'>{languaje === 'ES' ? 'SI' : 'YES'}</option>
                    <option value='false'>NO</option>
                </select>
            </div>
            <div className="col-12 col-md-6 col-xl-3 mt-2">
                <label htmlFor="educado">{languaje === 'ES' ? '¿ES EDUCADO?' : 'EDUCATED?'}</label>
                <select name="educado" id="educado" className='form-control' ref={educadoElement} onChange={handleFetch}>
                    <option value=""></option>
                    <option value='true'>{languaje === 'ES' ? 'SI' : 'YES'}</option>
                    <option value='false'>NO</option>
                </select>
            </div>
            <div className="col-12 col-md-6 mt-2">
                <div className="row">
                    <div className="col-12 col-md-6 pr-2">
                        <label htmlFor="edad">{languaje === 'ES' ? 'EDAD:' : 'AGE:'}</label>
                        <input type="text" id="edad" className="form-control" ref={edadValorElement} value={edadValor} onChange={handleEdadValorChange} onKeyDown={handleKeyDown} />
                    </div>
                    <div className="col-12 col-md-6 mt-4">
                        <select name="educado" id="educado" className='form-control mt-2' ref={edadUnidadElement} onChange={handleFetch}>
                            <option value=""></option>
                            <option value='meses'>{languaje === 'ES' ? 'MESES' : 'MONTHS'}</option>
                            <option value='años'>{languaje === 'ES' ? 'AÑOS' : 'YEARS'}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3 mt-2">
                <label htmlFor="convive">{languaje === 'ES' ? '¿CONVIVE CON OTROS DE SU ESPECIE?' : 'LIVES WITH OTHERS OF ITS SPECIES?'}</label>
                <select name="convive" id="convive" className='form-control' ref={conviveElement} onChange={handleFetch}>
                    <option value=""></option>

                    <option value='SI'>{languaje === 'ES' ? 'SI' : 'YES'}</option>
                    <option value='NO'>NO</option>
                    <option value='NO SE'>{languaje === 'ES' ? 'NO SE' : 'IDK'}</option>
                </select>
            </div>
            <div className="col-12 col-md-6 col-xl-3 mt-2">
                <label htmlFor="desparasitacion">{languaje === 'ES' ? '¿ESTA VACUNADO/DESPARAZITADO?' : 'VACCINATION/DEWORMING?'}</label>
                <select name="desparasitacion" id="desparasitacion" className='form-control' ref={desparasitacionElement} onChange={handleFetch}>
                    <option value=""></option>
                    <option value='true'>{languaje === 'ES' ? 'SI' : 'YES'}</option>
                    <option value='false'>NO</option>
                </select>
            </div>
            <div className="col-12 col-md-6 col-xl-3 mt-2">
                <label htmlFor="esterilizada">{languaje === 'ES' ? '¿ESTA ESTERILIZADO?' : 'PET STERILIZED?'}</label>
                <select name="esterilizada" id="esterilizada" className='form-control' ref={esterilizadaElement} onChange={handleFetch}>
                    <option value=""></option>
                    <option value='true'>{languaje === 'ES' ? 'SI' : 'YES'}</option>
                    <option value='false'>NO</option>
                </select>
            </div>
        </div>
    )
}
