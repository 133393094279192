/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useDebouncedCallback } from 'use-debounce';
import { AxiosResponse } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
    faTrash,
    faPencilAlt,
    faArrowLeft,
    faArrowRight,
    faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import Swal from 'sweetalert2';
import states from '../../../assets/contanst/states.json';

import { Loader } from "../../../components/ui/Loader";
import { useKW2P } from "../../../hooks/useKW2P";
import { UserContext } from "../../../context/UserContext";
import { AuthContext } from "../../../context/AuthContext";
import { FuncMap } from "../../../interfaces/mascotasInterfaces";
import "../../../style.css";


import { Modal, Button } from 'react-bootstrap'

import { useForm, useWatch } from "react-hook-form";
import moment from "moment";

const ITEMS_PER_PAGE = 15;


export function EventosAdopcion() {
    const signalsRef = useRef < FuncMap > ({})
    const { languaje } = useContext(UserContext);

    const { consultarCatalogo, eliminarCatalogo, customAction } =
        useKW2P();


    // const queries = {
    //     'payload->nombre': ({ value, onChange }: { value: any | string | null, onChange: Function | null }) => (
    //         <div className="col">

    //             <input
    //                 type="text"
    //                 className="form-control"
    //                 style={{ minWidth: '300px' }}
    //                 placeholder={
    //                     languaje === "ES" ? "Buscar evento" : "Search pet"
    //                 }
    //                 value={value}
    //                 onChange={v => onChange?.(v.target.value)}
    //             />

    //         </div>
    //     ),
    //     'payload->nuestro_canal': ({ value, onChange }: { value: any | string | null, onChange: Function | null }) => (
    //         <div className="col">
    //             <select
    //                 className="form-control"
    //                 style={{ minWidth: '300px' }}
    //                 value={value}
    //                 onChange={v => {
    //                     debugger
    //                     return onChange?.(v.target.value)
    //                 }}
    //             >
    //                 <option value="" selected>{languaje === 'ES' ? '¿Fue adoptado por medio de Callejeritos?' : 'He was adopted through Callejeritos?'}</option>
    //                 <option value="SI">{languaje === "ES" ? "SI" : "YES"}</option>
    //                 <option value="NO">NO</option>
    //             </select>
    //         </div>
    //     )
    // }
    const queries = {}



    const [usuarioMascotaDuenio, setUsuarioMascotaDuenio] = useState < any | null > (null);

    const eliminarEvento = (evento: any) => {
        return eliminarCatalogo({
            schema: "evento_adopcion",
            id: evento.id,
        })
    }
    const invitarEvento = (evento: any) => {
        return customAction({
            action: 'evento_adopcion:enviar_invitacion',
            evento_id: evento.id,
        })
    }

    const consultarEvento = (
        { query, page }:
            //@ts-ignore
            { query?: any, page?: number | null }): Promise<AxiosResponse<any>> => {
        return consultarCatalogo({
            schema: "evento_adopcion",
            ...page ? {
                limit: ITEMS_PER_PAGE,
                page: page,
            } : {},
            ...Object.keys(query ?? {}).length ? {
                filter: {
                    where: [
                        ...Object.entries(query ?? {}).map(([key, value]) => ({
                            field: key,
                            operator: "like",
                            value: `%${value}%`,
                            collate: "utf8mb4_unicode_ci"
                        })),
                    ],
                },
            } : {},
            order: [
                {
                    field: 'payload->fecha',
                    direction: 'asc'
                },
                {
                    field: 'payload->horario->de',
                    direction: 'asc'
                },
                {
                    field: 'payload->horario->a',
                    direction: 'asc'
                }
            ]
        });

    };

    const fetchData = async () => {
        consultarEvento({})
            .then(({ data: { data } }) => {
                // debugger
            })
    };




    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <div className="mascotas__container justify-content-start">


                <div className="mascotas__header">
                    <p>{languaje === "ES" ? "Eventos de adopción" : "Adoption Events"}</p>
                    <button className="btn btn-callejeritos" onClick={() => signalsRef.current['modal']({})}>Agregar +</button>
                </div>
                <div className="macotas__body">
                    <TablaEventos
                        estado={'adopcion'}
                        eliminarEvento={eliminarEvento}
                        consultarEvento={consultarEvento}
                        invitarEvento={invitarEvento}
                        onUpdateState={signalsRef.current?.['modal'] ?? (() => { })}
                        // @ts-ignore
                        signals={cb => signalsRef.current['table'] = cb}
                        queries={queries}
                    />
                </div>
            </div>

            <ModalEventos signals={(cb: (arc: any) => {}) => signalsRef.current['modal'] = cb} onFinish={() => {
                fetchData()//implementar funcion
                // @ts-ignore
                signalsRef.current['table']?.(true)
            }} />
        </>
    )
};



function TablaEventos({
    consultarEvento,
    eliminarEvento,
    invitarEvento,
    onUpdateState,
    signals,
    queries,
}: any) {
    const { languaje } = useContext(UserContext);
    const { handleError, setLoading, isLoading, user } = useContext(AuthContext);


    const [page, setPage] = useState < number > (1);
    const [lastPage, setLastPage] = useState < number > (0);
    const [query, setQuery] = useState < any > ({});
    const [eventos, setMascotas] = useState < any[] > ([]);



    const fetchData = async (sinLoader = false) => {


        try {
            !sinLoader && setLoading(true);
            const {
                data: {
                    data,
                    paginate
                },
            } = await consultarEvento({ query, page, sinLoader });

            setLastPage(paginate?.last_page ?? 0);
            console.log("eventos", data)
            // @ts-ignore
            setMascotas(data.map((m, i) => ({ ...m, index: (page - 1) * ITEMS_PER_PAGE + 1 + i })));

            setLoading(false);
        } catch (err: any) {
            setLoading(false);
            handleError(err);
        }
    };


    const onSendEvento = (event: any) => {
        Swal.fire({
            title: languaje === 'ES' ? `¿Está seguro de enviar la invitacion?`: `Are you sure you want to send the invitation?`,
            text: languaje === 'ES' ? 'Se enviará a todos los usuarios con al menos una mascota en adopcion' : "It will be sent to all users with at least one pet up for adoption",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: languaje === 'ES' ? '¡Sí, enviarlo!' : 'Yes, send it!',
            cancelButtonText: languaje === 'ES' ? 'Cancelar' : 'Cancel'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                invitarEvento(event).then(() => {
                    // debugger
                    Swal.fire(
                        languaje === 'ES' ? 'Eviado!' : 'Sent!',
                        languaje === 'ES' ? 'La invitacion fue enviada con éxito' : 'The invitation has been sent successfully',
                        'success'
                    );
                    fetchData();
                }).catch((error: any) => {
                    debugger
                    console.log(error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo sentimos ocurrio un error...',
                        text: 'Contacta con el administrador'
                    });
                }).finally(() => setLoading(false));
            }
        });
    };
    const onDeleteEvento = (event: any) => {
        Swal.fire({
            title: languaje === 'ES' ? `¿Está seguro de eliminar esté evento` : `Are you sure you want to delete this event?`,
            text: languaje === 'ES' ? '¡No podrás revertir esto!' : "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: languaje === 'ES' ? '¡Sí, bórralo!' : 'Yes, delete it!',
            cancelButtonText: languaje === 'ES' ? 'Cancelar' : 'Cancel'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                // debugger
                eliminarEvento(event).then(() => {
                    // debugger
                    Swal.fire(
                        languaje === 'ES' ? 'Eliminado' : 'Deleted!',
                        languaje === 'ES' ? 'El evento fue eliminado con éxito' : 'The event has been deleted successfully',
                        'success'
                    );
                    fetchData();
                }).catch((error: any) => {
                    debugger
                    console.log(error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo sentimos ocurrio un error...',
                        text: 'Contacta con el administrador'
                    });
                }).finally(() => setLoading(false));
            }
        });
    };
    const handleSearch = useDebouncedCallback(() => {
        fetchData(true)
    }, 500);
    useEffect(() => {
        handleSearch()
    }, [query]);
    useEffect(() => {
        fetchData();
        signals(fetchData)
    }, [page]);
    return (<>

        <div className="row w-100 mb-2">

            {Object.entries(queries).map(([key, Value]) => (
                // @ts-ignore
                <Value
                    key={key}
                    value={query[key]}
                    onChange={(v: any) => {
                        setQuery((q: any) => ({ ...q, [key]: v }))
                    }}
                />
            ))}

        </div>

        <div className="row">
            <div className="col-12">
                <div className="table-responsive">
                    <table className="table table-sm table-hover">
                        <thead>
                            <tr>

                                <th>{languaje === "ES" ? "DESCRIPCIÓN" : "DESCRIPTION"}</th>
                                <th>{languaje === "ES" ? "FECHA" : "DATE"}</th>
                                <th>{languaje === "ES" ? "HORARIO" : "HOURS"}</th>
                                <th>{languaje === "ES" ? "LUGAR" : "SITE"}</th>
                                <th>{languaje === "ES" ? "PARTICIPANTES" : "PARTICIPANTS"}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {console.log("array",mascotasPaginadas)} */}
                            {eventos.length === 0 ?
                                (<tr>
                                    <td colSpan={5} className="text-center" style={{ height: 150, placeContent: 'center' }}>
                                        {languaje === "ES" ? "No hay eventos" : "No events"}
                                    </td>
                                </tr>)
                                :
                                eventos.map((evento: any) => (
                                    // evento.payload.eliminado === "" || evento.payload.eliminado === false ?
                                    <tr key={evento.id}>

                                        <td>{evento?.payload?.descripcion}</td>
                                        <td>{moment(evento?.payload?.fecha).format('DD/MM/YY')}</td>
                                        <td>
                                            {
                                                moment(`${evento?.payload?.fecha} ${evento?.payload?.horario?.de}`).format('hh:mm a')}{' a '}{moment(`${evento?.payload?.fecha} ${evento?.payload?.horario?.a}`).format('hh:mm a')
                                            }


                                        </td>

                                        <td>
                                            {evento?.payload?.lugar?.direccion}, {evento?.payload?.lugar?.colonia}, {evento?.payload?.lugar?.cp} {evento?.payload?.lugar?.ciudad} {evento?.payload?.lugar?.estado}.
                                        </td>

                                        <td className="text-center">
                                            {evento?.payload?.mascotas_presentes?.length ?? 0}
                                        </td>



                                        <td>

                                            <div className="d-flex justify-content-end" style={{ gap: '.5rem' }}>
                                                <button
                                                    value={evento.id}

                                                    onClick={_ => onUpdateState(evento)}
                                                    style={{ placeContent: 'center' }}
                                                    className="btn btn-sm btn-editar "
                                                >
                                                    <FontAwesomeIcon icon={faPencilAlt as IconProp} />
                                                </button>
                                                <button
                                                    // onClick={() => banderaEliminar(mascota)}
                                                    onClick={() => onDeleteEvento(evento)}
                                                    className="btn btn-sm btn-outline-danger "
                                                >
                                                    <FontAwesomeIcon icon={faTrash as IconProp} />
                                                </button>
                                                <Link
                                                    to={`/eventos/adopcion/${evento.id}`}
                                                    className="btn btn-sm btn-detalles"
                                                    style={{ placeContent: 'center' }}
                                                >
                                                    {languaje === "ES" ? "Detalles" : "Details"}
                                                </Link>
                                                <button
                                                    className="btn btn-sm btn-outline-primary"
                                                    onClick={() => onSendEvento(evento)}
                                                >
                                                    <FontAwesomeIcon icon={faPaperPlane as IconProp} />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    // : null
                                ))}
                            {/* {console.log(usuarioMascota)} */}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div className="row">
            <div className="col-6"></div>
            <div className="col-6">
                <div className="row align-items-center">
                    <div className="col">
                        {/* <button className='form-control' onClick={prevHandle}>{languaje === 'ES' ? 'página anterior' : 'previous page'}</button> */}
                        {page > 1 ?
                            <button className="form-control" onClick={_ => setPage(p => Number(p) - 1)}>
                                <FontAwesomeIcon icon={faArrowLeft as IconProp} />
                            </button>
                            : null}
                    </div>
                    <div>
                        {page} de {lastPage}
                    </div>
                    <div className="col">
                        {/* <button className='form-control' onClick={nextHandler}>{languaje === 'ES' ? 'siguiente página' : 'next page'}</button> */}
                        {page < lastPage ?
                            <button className="form-control" onClick={_ => setPage(p => Number(p) + 1)}>
                                <FontAwesomeIcon icon={faArrowRight as IconProp} />
                            </button>
                            : null}
                    </div>
                </div>
            </div>
        </div>
        {isLoading && <Loader />}
    </>)
}



function ModalEventos({ signals, onFinish }: { signals: Function, onFinish: Function }) {
    const { actualizarCatalogoPublic } = useKW2P();
    const { languaje } = useContext(UserContext);
    const [selectedEvento, setSelectedEvento] = useState < any | null > (null);
    const [loading, setLoading] = useState < boolean > (false);
    const { formState: { errors }, handleSubmit, register, control, setValue, reset } = useForm()

    const handleUpdateState = (evento: any) => {
        debugger
        reset({})
        setSelectedEvento(evento);
        if (evento?.payload) {
            for (let [key, value] of Object.entries(evento.payload)) {
                setValue(key, value)
            }
        }
    }
    const onSubmit = (data: any) => {
        console.log(data)
        debugger
        setLoading(true)
        actualizarCatalogoPublic({
            id: selectedEvento?.id,
            schema: 'evento_adopcion',
            payload: {
                ...data
            }
        }).then(async ({ data: { data } }) => {
            await onFinish()
            setSelectedEvento(null)
        }).finally(() => setLoading(false))
    }
    useEffect(() => {
        signals(handleUpdateState)
    }, [])
    return (
        <>
            {loading ? <Loader /> : null}
            <Modal size="lg" centered show={!!selectedEvento} onHide={() => setSelectedEvento(null)}>
                <Modal.Header style={{ borderBottom: 'unset' }}>
                    <Modal.Title>
                        Nuevo evento de adopción
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="row">
                    <div className="col-12 mt-2">
                        <label htmlFor="descripcion">DESCRIPCIÓN:</label>
                        <textarea id="descripcion" className="form-control" placeholder={languaje === 'ES' ? 'Ingresa la descripcion del evento' : `Enter the descriptions of the event`} autoComplete="off"
                            {...register('descripcion', { required: languaje === 'ES' ? 'La descripcion del evento es requerido' : 'The event descriptions is required' })} />
                        {/* @ts-ignore */}
                        {errors.descripcion && <small className="text-danger">{errors.descripcion.message}</small>}
                    </div>
                    <div className="col-5 mt-2">
                        <label htmlFor="fecha">FECHA:</label>
                        <input id="fecha" className="form-control" autoComplete="off"
                            {...register('fecha', { required: languaje === 'ES' ? 'La fecha del evento es requerido' : 'The event date is required' })} type="date" />
                        {/* @ts-ignore */}
                        {errors.fecha && <small className="text-danger">{errors.fecha.message}</small>}
                    </div>
                    <div className="col-7 row">
                        <div className="col mt-2">
                            <label htmlFor="horario_de">DE:</label>
                            <input id="horario_de" className="form-control" autoComplete="off"
                                {...register('horario.de', { required: languaje === 'ES' ? 'La fecha del evento es requerido' : 'The event date is required' })} type="time" />
                            {/* @ts-ignore */}
                            {errors.horario?.de && <small className="text-danger">{errors.horario?.de.message}</small>}
                        </div>
                        <div className="col mt-2">
                            <label htmlFor="horario_a">A:</label>
                            <input id="horario_a" className="form-control" autoComplete="off"
                                {...register('horario.a', { required: languaje === 'ES' ? 'La fecha del evento es requerido' : 'The event date is required' })} type="time" />
                            {/* @ts-ignore */}
                            {errors.horario?.a && <small className="text-danger">{errors.horario?.a.message}</small>}
                        </div>
                    </div>

                    <div className="col-5 mt-2">
                        <label htmlFor="lugar_direccion">DIRECCIÓN:</label>
                        <input id="lugar_direccion" className="form-control" placeholder={languaje === 'ES' ? 'Ingresa la dirección del evento' : `Enter the address of the event`} autoComplete="off"
                            {...register('lugar.direccion', { required: languaje === 'ES' ? 'La dirección del evento es requerido' : 'The event address is required' })} />
                        {/* @ts-ignore */}
                        {errors.lugar?.direccion && <small className="text-danger">{errors.lugar.direccion?.message}</small>}
                    </div>
                    <div className="col-5 mt-2">
                        <label htmlFor="lugar_colonia">COLONIA:</label>
                        <input id="lugar_colonia" className="form-control" placeholder={languaje === 'ES' ? 'Colinia' : `Neighborhood`} autoComplete="off"
                            {...register('lugar.colonia', { required: languaje === 'ES' ? 'La colinia es requerido' : 'The neighborhood is required' })} />
                        {/* @ts-ignore */}
                        {errors.lugar?.colonia && <small className="text-danger">{errors.lugar.colonia?.message}</small>}
                    </div>
                    <div className="col-2 mt-2">
                        <label htmlFor="lugar_cp">CP:</label>
                        <input id="lugar_cp" className="form-control" placeholder={languaje === 'ES' ? 'CP' : `zip code`} autoComplete="off"
                            {...register('lugar.cp', {
                                required: languaje === 'ES' ? 'La cp es requerido' : 'The zip code is required',
                                pattern: {
                                    value: /^[0-9]{5}$/,
                                    message: languaje === 'ES' ? 'El CP debe ser de 5 digitos' : 'The zip code must be 5 digits'
                                },
                            })} maxLength={5} />
                        {/* @ts-ignore */}
                        {errors.lugar?.cp && <small className="text-danger">{errors.lugar?.cp?.message}</small>}
                    </div>
                    <div className="col-4 mt-2">
                        <label htmlFor="lugar_ciudad">CIUDAD:</label>
                        <input id="lugar_ciudad" className="form-control" placeholder={languaje === 'ES' ? 'Ciudad' : `City`} autoComplete="off"
                            {...register('lugar.ciudad', { required: languaje === 'ES' ? 'La ciudad es requerido' : 'The city is required' })} />
                        {/* @ts-ignore */}
                        {errors.lugar?.ciudad && <small className="text-danger">{errors.lugar.ciudad?.message}</small>}
                    </div>
                    <div className="col-4 mt-2">
                        <label htmlFor="lugar_estado">{languaje === 'ES' ? 'ESTADO' : 'STATE'}</label>
                        <select id="lugar_estado" className="form-control" {...register('lugar.estado',
                            { required: languaje === 'ES' ? 'Elije una estado' : 'Choose one state', }
                        )}
                        >
                            <option value="" disabled selected hidden>{languaje === 'ES' ? 'Estado' : 'State'}</option>
                            {states.map((tab: any) => (
                                <option key={tab.id} value={tab.value}>{tab.label}</option>
                            ))}
                        </select>
                        {/* @ts-ignore */}
                        {errors.lugar?.estado && <small className="text-danger">{errors.lugar?.estado?.message}</small>}
                    </div>
                    {/* <pre>{JSON.stringify({ selectedEvento }, null, 2)}</pre> */}
                </Modal.Body>
                <Modal.Footer style={{ borderTop: 'unset' }}>


                    <Button variant="secondary" onClick={_ => setSelectedEvento(null)}>
                        {languaje === 'ES' ? 'Cancelar' : 'Cancel'}
                    </Button>
                    {/* @ts-ignore */}
                    <Button variant="primary" onClick={handleSubmit(onSubmit, console.log)}>
                        {languaje === 'ES' ? 'Guardar' : 'Save'}
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    )
}