/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Link, useRouteMatch } from 'react-router-dom';

import { useKW2P } from '../../../hooks/useKW2P';
import { UserContext } from '../../../context/UserContext';
import { AuthContext } from '../../../context/AuthContext';
import { Loader } from '../../../components/ui/Loader';

import { ExpedienteAzulFooter } from '../../../components/ui/ExpedienteAzulFooter';
import { BuscadorComponentEvento } from '../../../components/Eventos/BuscadorComponentEvento';
import moment from 'moment';

export const EventosAdopciones = () => {
    const { url } = useRouteMatch();
    const { languaje } = useContext(UserContext);
    const { handleError, setLoading, isLoading } = useContext(AuthContext);
    const { consultarCatalogoPublic } = useKW2P();

    const [eventos, setEventos] = useState < any[] > ([]);

    const fetchData = async ({where, whereJoin}:{where?: any[], whereJoin?: any[]}={where:[],whereJoin:[]}) => {
        try {
            setLoading(true);
            if(whereJoin?.length){
                const { data: { data:mascotas } } = await consultarMascotas(whereJoin);
                where?.push({
                    field: 'payload->mascotas_presentes->*->mascota_id',
                    operator: 'regexp',
                    value: `[[:<:]]${mascotas.map((mascota:any)=>mascota.id).join('|')}[[:>:]]`
                })
                debugger
            }
            debugger
            const { data: { data } } = await consultarEventosAdopcion(where);
            debugger
            setEventos(data);
            setLoading(false);
        } catch (err: any) {
            setLoading(false);
            handleError(err);
        }
    };

    const consultarMascotas = (where?: any[]): Promise<AxiosResponse<any>> => {
        const BODY = {
            schema: 'mascotas',
            
                filter: {
                    where:[
                        {
                            field: "payload->eliminado",
                            operator: "!=",
                            value: true
                        },
                        {
                            field: 'payload->estado',
                            operator: '=',
                            value: 'adopcion'
                        },
                        ...where?.length?where:[],
                    ]
                }
            
        };

        return consultarCatalogoPublic(BODY);
    }

    const consultarEventosAdopcion = (where?: any[]): Promise<AxiosResponse<any>> => {
        const BODY = {
            schema: 'evento_adopcion',
            "with": [
                "mascotas_presentes"
            ],
            ...(where?.length) && {
                filter: {
                    ...where?.length && {where},
                }
            }
        };

        return consultarCatalogoPublic(BODY);
    }



    useEffect(() => {
        fetchData(
            // [{
            //     field: 'payload->estado',
            //     operator: '=',
            //     value: 'adopcion'
            // }]
        );
    }, []);

    return (
        <div className='mascotas__container'>
            <div className="mascotas__header">
                <p>{languaje === 'ES' ? 'Eventos de Adopción' : 'Adoption Events'}</p>
            </div>
            <div className="mascotas__body">
                <BuscadorComponentEvento scope='adopcion' fetchData={fetchData} />
                <div className="d-flex" style={{ gap: '20px', flexWrap: 'wrap' }}>
                    {
                        eventos.map((evento: any) => (

                            <EventCard to={`${url}/${evento?.id??''}`} evento={evento} scope='adopcion' key={evento.id} />

                        ))
                    }
                </div>
            </div>
            <div className="mascotas__footer">
                <ExpedienteAzulFooter />
            </div>
            {isLoading && <Loader />}
        </div>
    )
}


function EventCard({ evento, to }: any) {
    const { languaje } = useContext(UserContext);
    return (
        <Link to={to} className="bg-warning p-4 rounded-lg shadow-sm mb-4 eventcard d-flex flex-column justify-content-evenly w-100" style={{ maxWidth: 445 }}>
            <h3 className="font-weight-bold mb-3">
                {languaje === 'ES' ? 'Evento de Adopción' : 'Pet Adoption Event'}
            </h3>
            <p className="h5 mb-2">
                <strong>{languaje === 'ES' ? 'Descripción:' : 'Description:'}</strong> {evento?.payload?.descripcion}
            </p>
            <p className="h5 mb-2">
                <strong>{languaje === 'ES' ? 'Fecha:' : 'Date:'}</strong> {moment(evento?.payload?.fecha).format('LL')}
            </p>
            <p className="h5 mb-2">
                <strong>{languaje === 'ES' ? 'Hora:' : 'Time:'}</strong> {moment(`${evento?.payload?.fecha} ${evento?.payload?.horario?.de}`).format('hh:mm a')}{' - '}{moment(`${evento?.payload?.fecha} ${evento?.payload?.horario?.a}`).format('hh:mm a')}
            </p>
            <p className="h5">
                <strong>{languaje === 'ES' ? 'Lugar:' : 'Place:'}</strong> {evento?.payload?.lugar?.direccion}, {evento?.payload?.lugar?.colonia}, {evento?.payload?.lugar?.cp} {evento?.payload?.lugar?.ciudad} {evento?.payload?.lugar?.estado}.
            </p>
            <p className="h5">
                <strong>{languaje === 'ES' ? 'Mascotas que asistiran': 'Pets that will attend'}:</strong> {evento?.payload?.mascotas_presentes?.length??0}
            </p>
        </Link>
    )
}