import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { AuthContext, UserContext } from "../../../context";
import { Loader } from "../../../components/ui/Loader";
import { useKW2P } from "../../../hooks";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faPaperPlane, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useRouteMatch } from "react-router";
import { Modal, Button } from 'react-bootstrap'
import moment from "moment";
import { set, useForm } from "react-hook-form";
import { MascostasData, Mascota } from "../../../interfaces/mascotasInterfaces";
import {
    mascotaEdad,
    mascotaRaza,
    mascotaSexo,
    mascotaTamano,
} from "../../../helpers/callejeritosFunciones";
import { MascotaCard } from "../../../components/Mascotas/MascotaCard";
import Swal from "sweetalert2";

interface RouteParams {
    id: string;
}

export default function DetalleEventoAdopcion() {
    const signalsRef = useRef < any > ({});
    const history = useHistory();
    const { url } = useRouteMatch();
    const { languaje } = useContext(UserContext); // Obtenemos el idioma del contexto
    const { handleError, setLoading, isLoading, user } = useContext(AuthContext);
    const { consultarCatalogoPublic, consultarCatalogo, customAction } = useKW2P();
    const [evento, setEvento] = useState < any > ({});
    const [misMascotas, setMisMascotas] = useState < any > ([]);
    const usuario = useMemo(() => localStorage.getItem("CA_usuario"), []);
    const rol = useMemo(() => JSON.parse(usuario!), [usuario]);

    const { id } = useParams < RouteParams > ();

    const invitarEvento = () => {
        return customAction({
            action: 'evento_adopcion:enviar_invitacion',
            evento_id: id,
        })
    }

    const onSendEvento = (event: any) => {
        Swal.fire({
            title: languaje === 'ES' ? `¿Está seguro de enviar la invitacion?` : `Are you sure you want to send the invitation?`,
            text: languaje === 'ES' ? 'Se enviará a todos los usuarios con al menos una mascota en adopcion' : "It will be sent to all users with at least one pet up for adoption",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: languaje === 'ES' ? '¡Sí, enviarlo!' : 'Yes, send it!',
            cancelButtonText: languaje === 'ES' ? 'Cancelar' : 'Cancel'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                invitarEvento().then(() => {
                    // debugger
                    Swal.fire(
                        languaje === 'ES' ? 'Eviado!' : 'Sent!',
                        languaje === 'ES' ? 'La invitacion fue enviada con éxito' : 'The invitation has been sent successfully',
                        'success'
                    );
                }).catch((error: any) => {
                    debugger
                    console.log(error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Lo sentimos ocurrio un error...',
                        text: 'Contacta con el administrador'
                    });
                }).finally(() => setLoading(false));
            }
        });
    };
    const optenerMascotas = () => {
        consultarCatalogoPublic({
            schema: "mascotas",
            filter: {
                where: [
                    ...rol.roles?.includes("Administrador") ? [] : [
                        {
                            field: "payload->usuario_id",
                            operator: "=",
                            value: rol.id
                        },
                    ],
                    {
                        field: "payload->estado",
                        operator: "=",
                        value: 'adopcion'
                    },
                    {
                        field: "payload->eliminado",
                        operator: "!=",
                        value: true
                    }
                ]
            }
        }).then(({ data: { data } }: { data: { data: [] } }) => {
            setMisMascotas(data);
        })
    }
    const optenerEvento = () => {
        return consultarCatalogoPublic({
            schema: "evento_adopcion",
            "with-storage": ["*"],
            "with": ["*"],
            filter: {
                where: [
                    {
                        field: "id",
                        operator: "=",
                        value: id
                    }
                ]
            }
        }).then(({ data: { data } }: { data: { data: any[] } }) => {
            console.log(data.length, data)
            if (data.length < 1) {
                history.push(url.split('/').splice(0, 3).join('/'));
            } else {
                setEvento(data[0]);
            }
        }).catch((error) => {
            debugger
        }).finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        //@ts-ignore
        if (isNaN(id)) {
            history.push(url.split('/').splice(0, 3).join('/'));
        }
        setLoading(true);
        signalsRef.current['evento'] = optenerEvento;
        optenerEvento()
        return;

    }, [id]);

    useEffect(() => {
        if (rol !== null) {
            optenerMascotas();
        }
    }, [rol, evento]);

    if (isLoading) {
        return (
            <div className="container p-4 eventos" style={{ height: '80vh' }}>
                <Loader />
            </div>
        )
    }

    return (
        <>
            <div className="container p-4 eventos">
                <div className="bg-warning p-4 rounded-lg shadow-sm mb-4">
                    <h1 className="display-4 font-weight-bold mb-3">
                        {languaje === 'ES' ? 'Evento de Adopción' : 'Pet Adoption Event'}
                    </h1>
                    <p className="h5 mb-2">
                        <strong>{languaje === 'ES' ? 'Descripción:' : 'Description:'}</strong> {evento?.payload?.descripcion}
                    </p>
                    <p className="h5 mb-2">
                        <strong>{languaje === 'ES' ? 'Fecha:' : 'Date:'}</strong> {moment(evento?.payload?.fecha).format('LL')}
                    </p>
                    <p className="h5 mb-2">
                        <strong>{languaje === 'ES' ? 'Hora:' : 'Time:'}</strong> {moment(`${evento?.payload?.fecha} ${evento?.payload?.horario?.de}`).format('hh:mm a')}{' - '}{moment(`${evento?.payload?.fecha} ${evento?.payload?.horario?.a}`).format('hh:mm a')}
                    </p>
                    <p className="h5">
                        <strong>{languaje === 'ES' ? 'Lugar:' : 'Place:'}</strong> {evento?.payload?.lugar?.direccion}, {evento?.payload?.lugar?.colonia}, {evento?.payload?.lugar?.cp} {evento?.payload?.lugar?.ciudad} {evento?.payload?.lugar?.estado}.
                    </p>
                    <p className="h5 text-left pl-4 font-weight-bold">
                        <a href="/DOC-REQ.pdf" target="_blank" rel="noopener noreferrer" className="text-primary">Requisitos, protocolo y buenas prácticas para asistencia de rescatistas a eventos de adopciones responsables de Callejeritos México</a>
                    </p>
                </div>
                <div className="mascotas__header">
                    <h2 className="h3 font-weight-semibold mb-4">
                        {languaje === 'ES' ? 'Mascotas Participantes' : 'Participating Pets'}
                    </h2>
                    <div className="d-flex" style={{ gap: 5 }}>
                        {rol !== null ? (
                            <>
                                <button className="bg-warning rounded-lg shadow-sm p-2 d-flex align-items-center justify-content-center" style={{ cursor: 'pointer', gap: '5px' }} onClick={() => signalsRef.current['modal'](evento)} >

                                    <h5 className="text-center m-0">Agregar</h5>
                                    <FontAwesomeIcon icon={faPlus as IconProp} size="sm" />

                                </button>
                                {rol.roles?.includes("Administrador") ? (
                                    <button className=" rounded-lg shadow-sm p-2 d-flex align-items-center justify-content-center btn btn-sm btn-outline-primary" style={{ cursor: 'pointer', gap: '5px' }} onClick={onSendEvento} >

                                        <h5 className="text-center m-0">Invitar</h5>
                                        <FontAwesomeIcon icon={faPaperPlane as IconProp} size="sm" />

                                    </button>
                                ) : null}
                            </>
                        ) : null}
                    </div>
                </div>


                <div className="row mascotas__body" style={{ minHeight: '25vh' }}>




                    {evento?._relaciones?.mascotas_presentes?.map((mascota: any, i: number) => (
                        <div className="col-12 col-md-6 col-xl-3 card__container" key={mascota.id}>
                            <MascotaCard mascota={mascota} scope='adopcion' key={mascota.id} isEvento />
                        </div>
                    ))}

                </div>
            </div>

            <ModalParticipantes
                misMascotas={misMascotas}
                evento={evento}
                signals={(cb: Function) => signalsRef.current['modal'] = cb}
                signal={signalsRef}
                onFinish={() => {
                    signalsRef.current['table']?.(true)
                }}
            />
        </>
    );
}




function ModalParticipantes({ evento, onFinish, misMascotas, signal, signals }: { evento: any, onFinish: Function, misMascotas: Mascota[], signal: { current: any }, signals: Function }) {
    const { customAction } = useKW2P();
    const { languaje } = useContext(UserContext);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(-1);


    const usuario = useMemo(() => localStorage.getItem("CA_usuario"), []);
    const rol = useMemo(() => JSON.parse(usuario!), [usuario]);

    const addParticipante = async (data: any) => {
        const { id } = data;
        setLoading(id)
        customAction({
            action: 'evento_adopcion:agregar_participante',
            mascota_id: id,
            evento_id: evento.id,
            usuario_id: rol.id,
        }).then(({ data }: { data: any }) => {
            return signal.current['evento']();
        }).finally(() => {
            setLoading(-1)
        })
    }
    const removeParticipante = async (data: any) => {
        const { id } = data;
        setLoading(id)
        customAction({
            action: 'evento_adopcion:retirar_participante',
            mascota_id: id,
            evento_id: evento.id,
            usuario_id: rol.id,
        }).then(({ data }: { data: any }) => {
            return signal.current['evento']();
        }).finally(() => {
            setLoading(-1)
        })
    }


    const handleUpdateState = (evento: any) => {
        setShow(true);
    }

    useEffect(() => {
        signals(handleUpdateState)
    }, [])

    return (
        <Modal size="xl" centered show={show} onHide={() => setShow(false)}>
            <Modal.Header style={{ borderBottom: 'unset' }}>
                <Modal.Title>
                    Nuevo evento de adopción
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="row mascotas__container" style={{
                minHeight: 'unset'
            }}>
                <table className="table table-sm table-hover">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{languaje === "ES" ? "NOMBRE" : "NAME"}</th>

                            <th>COLOR</th>
                            <th>{languaje === "ES" ? "EDAD" : "AGE"}</th>
                            <th>{languaje === "ES" ? "SEXO" : "GENDER"}</th>
                            <th>{languaje === "ES" ? "TAMAÑO" : "SIZE"}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* {console.log("array",mascotasPaginadas)} */}
                        {misMascotas.length === 0 ?
                            (<tr>
                                <td colSpan={7} className="text-center" style={{ height: 150, placeContent: 'center' }}>
                                    {languaje === "ES" ? "No hay mascotas" : "No have pets"}
                                </td>
                            </tr>)
                            :
                            misMascotas.map((mascota: Mascota, i: number) => (
                                // evento.payload.eliminado === "" || evento.payload.eliminado === false ?
                                <tr key={mascota.id}>

                                    <td>{1 + i}</td>
                                    <td>{mascota.payload.nombre}</td>

                                    <td>{mascota.payload.color}</td>
                                    <td>{mascotaEdad(mascota.payload.edad, languaje)}</td>
                                    <td>{mascotaSexo(mascota.payload.sexo, languaje)}</td>
                                    <td>{mascotaTamano(mascota.payload.tamano, languaje)}</td>


                                    <td>

                                        <div className="d-flex justify-content-end" style={{ gap: '.5rem' }}>
                                            {loading == mascota.id ? <button className="btn btn-sm btn-light d-flex align-items-center">
                                                <div className="loader_btn" />
                                            </button> : ((evento?.payload?.mascotas_presentes ?? []).find((f: { mascota_id: number, usuario_id: number }) => f.mascota_id == mascota.id) ?
                                                <button className="btn btn-sm btn-danger" onClick={() => {
                                                    removeParticipante(mascota)
                                                }}>
                                                    {languaje === "ES" ? "Retirar" : "Remove"}
                                                </button> :
                                                <button className="btn btn-sm btn-primary" onClick={() => {
                                                    addParticipante(mascota)
                                                }}>
                                                    {languaje === "ES" ? "Agregar" : "Add"}
                                                </button>)
                                            }
                                        </div>
                                    </td>
                                </tr>
                                // : null
                            ))}
                        {/* {console.log(usuarioMascota)} */}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer style={{ borderTop: 'unset' }}>

            </Modal.Footer>
        </Modal>
    )
}

