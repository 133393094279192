import React, { useContext } from 'react'
import { Redirect, Route, useLocation } from 'react-router';
import { AuthContext } from '../context/AuthContext';

interface Props {
    exact: boolean;
    path: string;
    component: React.ComponentType<any>;
}

export const AdminRoute = ({ component: Component ,...rest }: Props) => {
    const {user} = useContext(AuthContext);
    const location = useLocation();


    return (
        <Route { ...rest }>
            {/* @ts-ignore */}
            { user?.roles?.includes("Administrador") ? <Component /> : <Redirect to={{ pathname:'/login', state: { from: location } }} /> }
        </Route>
    )
};
