import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import { AuthContext } from '../../../context/AuthContext';
import { MascotaForm } from '../../../components/Mascotas/MascotaForm';
import { Loader } from '../../../components/ui/Loader';
import { ExpedienteAzulFooter } from '../../../components/ui/ExpedienteAzulFooter';

export const RegistrarRehabilitacion = () => {

    const usuario_dueño = localStorage.getItem('CA_usuario');
    const rol = JSON.parse(usuario_dueño!);

    const { languaje } = useContext(UserContext);
    const { isLoading } = useContext(AuthContext);

    return (
        <>
            <div className='mascotas__container mb-0'>
                <div className="mascotas__header">
                    <h3>{ languaje === 'ES' ? 'Registrar Mascota para Rehabilitación' : 'Register Pet to Rehabilitation' }</h3>
                    <Link to="/mascotas/rehabilitacion" className="btn btn-callejeritos">{ languaje === 'ES' ? 'REGRESAR' : 'BACK' }</Link>
                </div>
                <div className="mascotas__body">
                    <MascotaForm mascota={ null } dueno={ rol } scope='rehabilitacion' modificar={true}/>
                </div>
                <div className="mascotas__footer mb-2">
                    <ExpedienteAzulFooter/>
                </div>
            </div>
            { isLoading && <Loader/>}   
        </>
    )
}
