import React, { useContext } from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import { PrivateRoute } from './PrivateRoute';
import { SpanishRoute } from './SpanishRoute';
import { EnglishRoute } from './EnglishRoute';
import { UserContext } from '../context';
import { EventosAdopcion } from '../pages/Eventos/Adopcion/EventosAdopcion';
import DetalleEventoAdopcion from '../pages/Eventos/Adopcion/DetalleEventoAdopcion';
import { EventosAdopciones } from '../pages/Eventos/Adopcion/EventosAdopciones';
import { AdminRoute } from './AdminRoute';

export const EventosRouter = () => {

    const { url } = useRouteMatch();
    const { languaje } = useContext(UserContext);

    return (
        <Switch>
            <Route exact path={`${url}`}>
                <Redirect to={`${url}/${languaje === 'ES' ? 'adopcion' : 'adoption'}`} />
            </Route>

            <SpanishRoute exact path={`/eventos/adopcion`} component={EventosAdopciones} />
            <EnglishRoute exact path={`/events/adoption`} component={EventosAdopciones} />

            {/* <PrivateRoute exact path={`${url}/adopcion/registro`} component={EventosAdopcion} /> */}
            <AdminRoute exact path={`${url}/adopcion/registro`} component={EventosAdopcion} />

            <SpanishRoute exact path={`/eventos/adopcion/:id`} component={DetalleEventoAdopcion} />
            <EnglishRoute exact path={`/events/adoption/:id`} component={DetalleEventoAdopcion} />




            {/* <PrivateRoute exact path={`${url}/adopcion/registrar`} component={RegistrarAdopcion} />
            <PrivateRoute exact path={`${url}/adopcion/editar/:id`} component={EditarAdopcion} />
            
            <Route exact path={`${url}/:scope/:id`} component={MascotaDetalle} />
            
            <SpanishRoute exact path={`/mascotas/rehabilitacion`} component={MascotasRehabilitacion} />
            <EnglishRoute exact path={`/pets/rehabilitation`} component={MascotasRehabilitacion} />

            <PrivateRoute exact path={`${url}/registrar`} component={RegistrarRehabilitacion} />
            <PrivateRoute exact path={`${url}/rehabilitacion/editar/:id`} component={EditarRehabilitacion} />

            <PrivateRoute exact path={`${url}/adoptadas`} component={MascotasAdotadas} />

            <PrivateRoute exact path={`${url}/mis-adopciones`} component={MisAdopciones} />
            <PrivateRoute exact path={`${url}/mis-rehabilitaciones`} component={MisRehabilitaciones} /> */}

        </Switch>
    )
}
